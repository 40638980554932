
import { defineComponent, ref, computed, onMounted } from "vue";
import Validation from "@/components/Validation.vue";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import DefaultLayout from "@/layout/DefaultLayout.vue";
import useVuelidate from "@vuelidate/core";
import { maxLength, minLength, required, sameAs } from "@vuelidate/validators";
import { useStore } from "vuex";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { useRoute } from "vue-router";
import GuestLayout from "@/layout/GuestLayout.vue";

export default defineComponent({
  name: "ResetPassword",
  components: {
    GuestLayout,
    DefaultLayout,
    Validation,
  },
  setup() {
    const password = ref<string>("");
    const confirmPassword = ref<string>("");
    const rules = {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(30),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(password),
      },
    };
    const v$ = useVuelidate(rules, { password, confirmPassword });
    const passwordFieldType = ref("password");
    const confirmPasswordFieldType = ref("password");

    const store = useStore();
    const route = useRoute();

    const setStudentPasswordAction = (options: ICrudOptions) => {
      store.dispatch("student-password/simplePost", options);
    };

    const resetAction = () => {
      store.dispatch("student-password/resetSave");
    };

    const getError = computed(() => store.getters["student-password/getError"]);

    const passwordResetResult = computed(() => store.getters["student-password/getDataItem"]);

    const loading = computed(() => store.getters["student-password/getIsLoading"]);

    const isRequestSent = computed(() => {
      return !isUndefined(passwordResetResult.value) && !isNull(passwordResetResult.value);
    });

    onMounted(() => {
      resetAction();
    });

    const togglePassword = () => {
      passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password";
    };

    const toggleConfirmPassword = () => {
      confirmPasswordFieldType.value = confirmPasswordFieldType.value === "password" ? "text" : "password";
    };

    const onSubmit = () => {
      v$.value.$touch();

      if (v$.value.$invalid) {
        return;
      }

      setStudentPasswordAction({
        resource: "set-student-password",
        hideSuccessMessage: true,
        params: {
          rawPassword: password.value,
          passwordToken: route.query.passwordToken,
        },
        descriptionField: "",
      });
    };

    return {
      v$,
      password,
      confirmPassword,
      passwordFieldType,
      confirmPasswordFieldType,
      toggleConfirmPassword,
      togglePassword,
      onSubmit,
      isRequestSent,
      loading,
      passwordResetResult,
    };
  },
});
